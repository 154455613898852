import defaultConfig from '../config/config.json';
import { config as cfg, Configuration, resetRemoteConfig } from './config';
import { RIReleaseData } from './services/api/generated.RibesProtocol';

/**
 * Overrides config
 * @param override Configuration that is used for overriding
 */
function overrideConfig(override: Configuration)
{
    let isPresent = function isPresent(obj: any): boolean
    {
        return obj !== undefined && obj !== null;
    }

    if(isPresent(override?.configuration?.debug))
    {
        let debug: boolean = override?.configuration?.debug;

        if(typeof override.configuration.debug == "string")
        {
            debug = (<string>override.configuration.debug).toLowerCase() == "true";
        }

        cfg.configuration.debug = debug;
    }

    if(isPresent(override?.configuration?.awsEnabled))
    {
        let aaa: boolean = override?.configuration?.awsEnabled;

        if(typeof override.configuration.awsEnabled == "string")
        {
            aaa = (<string>override.configuration.awsEnabled).toLowerCase() == "true";
        }

        cfg.configuration.awsEnabled = aaa;
    }

    if(isPresent(override?.configuration?.avroEnabled))
    {
        let avroEnabled: boolean = override?.configuration?.avroEnabled;

        if(typeof override.configuration.avroEnabled == "string")
        {
            avroEnabled = (<string>override.configuration.avroEnabled).toLowerCase() == "true";
        }

        cfg.configuration.avroEnabled = avroEnabled;
    }

    if(isPresent(override?.configuration?.apiBaseUrl))
    {
        cfg.configuration.apiBaseUrl = override.configuration.apiBaseUrl;
    }

    if(isPresent(override?.configuration?.emulateElectron))
    {
        cfg.configuration.emulateElectron = override.configuration.emulateElectron;
    }

    if(isPresent(override?.general?.theme))
    {
        cfg.general.theme = override.general.theme;
    }

    if(isPresent(override?.general?.language))
    {
        cfg.general.language = override.general.language;
    }
}

/**
 * Function used for loading configuration
 */
export async function loadConfig(): Promise<void>
{
    let loadJson = async path =>
    {
        let response = await fetch(new Request(path));

        return await response.json();
    };

    Object.keys(defaultConfig).forEach(key =>
    {
        cfg[key] = defaultConfig[key];
    });

    try
    {
        //default configuration
        let config: Configuration = await loadJson('local/config');

        Object.keys(config).forEach(key =>
        {
            cfg[key] = config[key];
        });
    }
    catch(e)
    {
        console.log('failed to load default configuration');
    }

    try
    {
        //config override from env variables
        let configOverride = await loadJson('local/configEnv');

        overrideConfig(configOverride);
    }
    catch(e)
    {
        console.log('failed to load environment configuration');
    }
    try
    {
        const remoteConfig = await loadJson('api/config/release');
        resetRemoteConfig(remoteConfig)
        cfg.configuration.recaptchaSiteKey = remoteConfig.reCaptchaSiteKey
        cfg.configuration.awsEnabled = !!cfg.configuration?.recaptchaSiteKey
    }
    catch(e) {
        console.log(`failed to load remote config : ${e}`);
        const remoteConfig: RIReleaseData = {
            releaseId: {
                artifactId: "larix",
                company: "Arbor AI s.r.o.",
                groupId: "sk.arborai.lucus.larix",
                id: "ERROR-ID",
                name: "Larix",
                version: "ERROR",
            },
            hlasenie: {
                kod: "SITE-ERROR",
                povod: "LARIX",
                vaha: "5",
                sprava: "Server je mimo prevádzku"
            }
        }
        resetRemoteConfig(remoteConfig)
    }
}
